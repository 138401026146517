import { PUBLIC_HOST } from "../../config.js";
import {
	type ProjectUrlId,
	type FeatureUrlId,
	type ScenarioUrlId,
	type StepUrlId,
	type AppUrlId,
} from "../../types/entity.js";

export const rootPath = () => "/";

export const logoutPath = () => "/auth/logout";

export const createOrgPath = () => "/teams/create";

export const onboardingPath = () => "/start";

export const pricingPath = () => "/pricing";

export const billingPath = () => "/billing";

export const orgPath = () => "/organisation";

export const invitePath = ({ hash }: { hash: string }) => `/join/${hash}`;

export const createAppPath = () => "/apps/create";

export const appPath = ({ appId }: { appId: AppUrlId }) => `/apps/${appId}`;

export const appSettingsPath = ({ appId }: { appId: AppUrlId }) => `/apps/${appId}/settings`;

export const documentationPath = ({ appId }: { appId: AppUrlId }) => `/apps/${appId}/documentation`;

export const projectPath = ({ appId, projectId }: { appId: AppUrlId; projectId: ProjectUrlId }) =>
	`/apps/${appId}/projects/${projectId}`;

export const projectPreviewPath = ({ appId, projectId }: { appId: AppUrlId; projectId: ProjectUrlId }) =>
	`/apps/${appId}/projects/${projectId}/review`;

export const projectPreviewScenarioPath = ({
	appId,
	projectId,
	scenarioId,
}: {
	appId: AppUrlId;
	projectId: ProjectUrlId;
	scenarioId: ScenarioUrlId;
}) => `/apps/${appId}/projects/${projectId}/review/${scenarioId}`;

export const projectFeaturePath = ({
	appId,
	projectId,
	featureId,
}: {
	appId: AppUrlId;
	projectId: ProjectUrlId;
	featureId: FeatureUrlId;
}) => `/apps/${appId}/projects/${projectId}/features/${featureId}`;

export const featurePath = ({ appId, featureId }: { appId: AppUrlId; featureId: FeatureUrlId }) =>
	`/apps/${appId}/documentation/${featureId}`;

export const projectScenarioPath = ({
	appId,
	projectId,
	featureId,
	scenarioId,
}: {
	appId: AppUrlId;
	projectId: ProjectUrlId;
	featureId: FeatureUrlId;
	scenarioId: ScenarioUrlId;
}) => `/apps/${appId}/projects/${projectId}/features/${featureId}/${scenarioId}`;

export const scenarioPath = ({
	appId,
	featureId,
	scenarioId,
}: {
	appId: AppUrlId;
	featureId: FeatureUrlId;
	scenarioId: ScenarioUrlId;
}) => `/apps/${appId}/documentation/${featureId}/${scenarioId}`;

export const projectStepPath = ({
	appId,
	projectId,
	featureId,
	scenarioId,
	stepId,
}: {
	appId: AppUrlId;
	projectId: ProjectUrlId;
	featureId: FeatureUrlId;
	scenarioId: ScenarioUrlId;
	stepId: StepUrlId;
}) => `/apps/${appId}/projects/${projectId}/features/${featureId}/${scenarioId}/${stepId}`;

export const stepPath = ({
	appId,
	featureId,
	scenarioId,
	stepId,
}: {
	appId: AppUrlId;
	featureId: FeatureUrlId;
	scenarioId: ScenarioUrlId;
	stepId: StepUrlId;
}) => `/apps/${appId}/documentation/${featureId}/${scenarioId}/${stepId}`;

export const appTemplatesPath = ({ appId }: { appId: AppUrlId }) => `/apps/${appId}/settings/templates`;

export const appTemplatePath = ({ appId, template }: { appId: AppUrlId; template: string }) =>
	`/apps/${appId}/settings/templates/${template}`;

export const integrationSettingsPath = ({ appId, integration }: { appId: AppUrlId; integration: string }) =>
	`/apps/${appId}/settings/integrations/${integration}`;

export const webhookAtlassianPath = () => "/webhook/atlassian";

export const webhookJiraPath = () => "/webhook/jira";

export const callbackPath = ({ service }: { service: string }) => `/callback/${service}`;

export const landingPath = ({ service }: { service: string }) => `/landing/${service}`;

export const absoluteUrl = (path: string) => `${PUBLIC_HOST}${path}`;

export const apiFigmaCommentsPath = () => "/api/figma/comments";

export const apiLiveblocksAutocomplete = () => "/api/users/liveblocks-autocomplete";
